.p-datatable>.p-datatable-wrapper {
  overflow: unset !important;
}

table, .p-datatable-wrapper {
  margin: unset !important;
  border-collapse: unset !important;
  border-radius: 5px !important;
}

.p-datatable-resizable .p-datatable-thead>tr>th,
.p-datatable-resizable .p-datatable-tfoot>tr>td,
.p-datatable-resizable .p-datatable-tbody>tr>td {
  overflow: hidden;  
  white-space: unset !important;  
}

datatable-resizable-table>.p-datatable-tbody>tr>td {
  overflow: hidden;
  white-space: unset !important;
}

.p-datatable-tbody>tr>td {
  text-align: center !important;
}

.p-datatable .p-column-resizer {
  width: 0rem !important; 
}

.p-datatable .p-datatable-thead>tr>th {
  background-color: #fff;
  text-align: center !important;
  color: var(--blue) !important;
}

.p-datatable .p-datatable-thead>tr>th:last-child {
  border-top-right-radius: 5px !important;
}

.p-datatable .p-datatable-thead>tr>th:first-child {
  border-top-left-radius: 5px !important;
}

.p-datatable .p-datatable-tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 5px !important;
}

.p-datatable .p-datatable-tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 5px !important;
}

.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-radius: 5px !important;
}

.p-datatable .p-datatable-thead>tr>td {
  border-left: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.p-datatable .p-datatable-thead>tr>td:last-child {
  border-right: 1px solid #dee2e6 !important;
}