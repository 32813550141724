.alert {
  opacity: 1 !important;
  width: 20%;  
  z-index: 12000;
  height: 60px;
  right: 39px; 
  top: 20px;  
  border-radius: 7px;  
}

.alert-success {
  background-color: var(--green);
  border-top-color: var(--green);
  border-right-color: var(--green);
  border-bottom-color: var(--green);
  border-left: 6px solid #699537;
}

.alert-danger {
  background-color: var(--red);
  border-top-color: var(--red);
  border-right-color: var(--red);
  border-bottom-color: var(--red);
  border-left: 6px solid #c3372c;
}

.alert-info {
  background-color: var(--infoBlue);
  border-color: var(--infoBlue);
  border-top-color: var(--infoBlue);
  border-right-color: var(--infoBlue);
  border-bottom-color: var(--infoBlue);
  border-left: 6px solid #0486c0;
}

@media (min-width: 375px) and (max-width: 431px) {  
  .alert {
    width: 82% !important;
  }
}