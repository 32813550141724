.font7 { font-size: 7px !important; }
.font8 { font-size: 8px !important; }
.font9 { font-size: 9px !important; }
.font10 { font-size: 10px !important; }
.font11 { font-size: 11px !important; }
.font12 { font-size: 12px !important; }
.font13 { font-size: 13px !important; }
.font14 { font-size: 14px !important; }
.font15 { font-size: 15px !important; }
.font16 { font-size: 16px !important; }
.font17 { font-size: 17px !important; }
.font18 { font-size: 18px !important; }
.font19 { font-size: 19px !important; }
.font20 { font-size: 20px !important; }
.font21 { font-size: 21px !important; }
.font22 { font-size: 22px !important; }
.font23 { font-size: 23px !important; }
.font24 { font-size: 24px !important; }
.font25 { font-size: 25px !important; }
.font26 { font-size: 26px !important; }
.font27 { font-size: 27px !important; }
.font28 { font-size: 28px !important; }
.font29 { font-size: 29px !important; }
.font30 { font-size: 30px !important; }
.font80 { font-size: 80px !important; }
.font120 { font-size: 120px !important; }
.bold { font-weight: 600 !important; }
.italic { font-style: italic !important; }
.cursorNotAllowed { cursor: not-allowed !important; }
.cursorPointer { cursor: pointer !important; }
.textGreyb9 { color: #b9b9b9 !important; }
.textWhite { color: #fff !important; }
.textBlack { color: var(--black) !important; }
.textBlue { color: var(--blue) !important; }
.textGreen { color: var(--green) !important; }
.textOrange { color: var(--orange) !important; }
.textRed { color: var(--red) !important; }
.textTransparent { color: transparent !important; }
.padding2 { padding: 2px !important; }
.padding3 { padding: 3px !important; }
.padding4 { padding: 4px !important; }
.padding5 { padding: 5px !important; }
.padding6 { padding: 6px !important; }
.padding7 { padding: 7px !important; }
.padding8 { padding: 8px !important; }
.padding9 { padding: 9px !important; }
.padding10 { padding: 10px !important; }
.padding11 { padding: 11px !important; }
.padding12 { padding: 12px !important; }
.padding13 { padding: 13px !important; }
.padding14 { padding: 14px !important; }
.padding15 { padding: 15px !important; }
.padding50 { padding: 50px !important; }