.change-scrollbar-color ::ng-deep ::-webkit-scrollbar-track {
  background: #fff;
  transition: 0.7s;
}

.change-scrollbar-color ::ng-deep ::-webkit-scrollbar {
  width: 5px !important;
  height: 8px;
}

.change-scrollbar-color ::ng-deep ::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.change-scrollbar-color-darkMode ::ng-deep ::-webkit-scrollbar {
  width: 5px !important;
  height: 8px;
}

.change-scrollbar-color-darkMode ::ng-deep ::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 10px;
}

.change-scrollbar-color-darkMode ::ng-deep ::-webkit-scrollbar-track {
  background-color: var(--dark);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--orange) !important;
}