.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: var(--green) !important;
  --mdc-switch-selected-handle-color: var(--green) !important;
  --mdc-switch-selected-hover-state-layer-color: var(--green) !important;
  --mdc-switch-selected-pressed-state-layer-color: var(--green) !important;
  --mdc-switch-selected-focus-handle-color: var(--green) !important;
  --mdc-switch-selected-hover-handle-color: var(--green) !important;
  --mdc-switch-selected-pressed-handle-color: var(--green) !important;
  --mdc-switch-selected-focus-track-color: var(--green) !important;
  --mdc-switch-selected-hover-track-color: var(--green) !important;
  --mdc-switch-selected-pressed-track-color: var(--green) !important;
  --mdc-switch-selected-track-color: var(--green) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: var(--blue) !important;
  background-color: var(--blue) !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__mixedmark {
  border-color: #fff !important;
  color: #fff !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff !important;
}

.mdc-checkbox {
  padding: unset !important;
}

.mdc-checkbox__background {
  transform: translate(-10px, -10px) !important;
}

.mat-mdc-checkbox-touch-target {
  width: fit-content !important;
}

.mat-mdc-checkbox label {
  margin: 0 !important;
  transform: translate(10px, 1px) !important;
}

.mdc-checkbox__native-control mdc-checkbox--selected {
  width: fit-content !important;
}

.mdc-checkbox__native-control {
  width: inherit !important;
  height: fit-content !important;
}