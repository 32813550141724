span {
  font-family: 'Poppins', sans-serif !important;
}

.disable-selection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.control-bar {
  z-index: 3;
  display: flex;
}

.control-bar-btn-icon {  
  transform: translateY(2px);
}

.control-bar-btn-text {
  font-weight: 500;
  margin-left: 5px;
  border-bottom: 1px solid var(--green) !important;
}

.arrow-column {
  width: 30px !important;
  max-width: 30px !important;
  transition: 0.1s;
}

.sorted-column {
  word-wrap: break-word;
  display: inline-block;
}

.p-float-label {
  margin-top: 10px !important;
}

.p-inputtext:enabled:focus {
  box-shadow: unset !important;
}

.p-inputtext:enabled:hover {
  border: 1px solid #ced4da !important;
}

.p-datatable-thead {
  position: sticky !important;
  top: 0px !important;
  z-index: 5 !important;
}

.range-filter-btn {
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}

.range-filter-apply-btn {
  outline: unset; 
  padding: 0px 7px;
}

.input-filter {
  border: 1px solid #0000003b;  
  box-shadow: none;
}

.active-filter {
  border-bottom: 1px solid black;
}

.active-filter-dark {
  border-bottom: 1px solid white;
}

.compact-grid {
  max-width: 200px !important;
  text-overflow: ellipsis;
}

.no-records {  
  margin-top: 4px;
}

td,
tr,
th {
  text-align: center;
  margin: unset !important;
  vertical-align: middle !important;
}

thead>tr>th:last-child {
  border-top-right-radius: 5px !important;
}

thead>tr>th:first-child {
  border-top-left-radius: 5px !important;
}

tbody>tr:last-child>td:last-child {
  border-bottom-right-radius: 5px !important;
}

tbody>tr:last-child>td:first-child {
  border-bottom-left-radius: 5px !important;
}

@media (min-width: 375px) and (max-width: 431px) {  
  .control-bar {    
    display: unset;
  }
}