.p-tooltip {
  background: var(--dark)  !important;
  width: auto !important;
  border-radius: 4px !important;
  max-width: 40% !important;
  backdrop-filter: blur(7px) !important;
}

.p-tooltip-arrow {
  display: none !important;
}

.p-tooltip-text {
  background: var(--dark) !important;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important;
  font-size: 13px;
  color: #fff !important;
  font-family: 'Poppins', sans-serif !important;
  word-wrap: unset !important;
  height: auto !important;
  display: flex !important;
  align-items: center !important;
  backdrop-filter: blur(7px) !important;
  padding: 4px !important;
}