/*#region Imports*/
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "/src/assets/alerts.css";
@import "/src/assets/animations.css";
@import "/src/assets/burger-icon.css";
@import "/src/assets/controls.css";
@import "/src/assets/grids.css";
@import "/src/assets/loader.css";
@import "/src/assets/material-checkboxes.css";
@import "/src/assets/material-tabs.css";
@import "/src/assets/prime-datatables.css";
@import "/src/assets/prime-dialogs.css";
@import "/src/assets/prime-tooltips.css";
@import "/src/assets/scrollbar.css";
@import "/src/assets/typography.css";
@import url("../node_modules/devexpress-gantt/dist/dx-gantt.css");
@import url("/node_modules/ace-builds/css/ace.css");  
@import url("/node_modules/ace-builds/css/theme/dreamweaver.css");  
@import url("/node_modules/ace-builds/css/theme/ambiance.css");  
@import url("/node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("/node_modules/@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("/node_modules/@devexpress/analytics-core/dist/css/dx-analytics.dark.css"); 
/*#endregion*/

:root {
  --blue: #1e305a;
  --green: #72b92b;
  --orange: #ea8c20;
  --black: #4a4a4a;
  --darkBlue: #1a2641;
  --light-blue: #eaf1ff;
  --midBlue: #203158;
  --red: #f44336;
  --dark: #5c5c5c;
  --dark-second: #ffffff24;
  --dark-third: #00000069;
  --dark-dark: #454545;
  --infoBlue: #03a9f4;
  --yellow: #ffd740;  
}

body, html {
  margin: unset !important;
  padding: unset !important;
  overflow-x: clip;
  overflow-y: hidden !important;
  height: 100%; 
}

.full-width { width: 100%; }
.no-width { width: 0%; }
.fit-content-max-width { max-width: fit-content !important; }
.full-vh-height { height: 100vh !important; }
.auto-height { height: auto !important; }

.relative { position: relative !important; }
.absolute { position: absolute !important; }
.sticky { position: sticky !important; }
.fixed { position: fixed !important; }

.flex { display: flex !important; }
.block { display: block !important; }
.inline-block { display: inline-block !important; }
.display-none { display: none !important; }
.display-content { display: contents !important; }
.display-unset { display: unset !important; }

.justify-centre { justify-content: center; }
.justify-left { justify-content: left; }
.justify-right { justify-content: right; }
.align-centre { align-items: center; }
.align-end { align-items: end; }

.overflow-x-hidden { overflow-x: hidden !important; }
.text-centre { text-align: center; }
.b-radius { border-radius: 4px !important; }

.unset-outline { outline: unset !important; }
.unset-box-shadow { box-shadow: unset !important; }
.blur { backdrop-filter: blur(7px); }
.overflow-auto { overflow-x: auto !important; }
.overflow-scroll { overflow-x: scroll !important; }

.bg-green { background-color: var(--green) !important; }
.bg-blue { background-color: var(--blue) !important; }
.bg-orange { background-color: var(--orange) !important; }
.bg-dark { background-color: var(--dark) !important; }
.bg-dark-secondary { background-color: var(--dark-second) !important; }
.bg-dark-tertiary { background-color: var(--dark-third) !important; }
.bg-dark-dark { background-color: var(--dark-dark) !important; }
.bg-light-blue { background-color: var(--light-blue) !important; }
.bg-white { background-color: #fff; }
.bg-none { background: transparent !important; }
.bg-gradient { background-image: linear-gradient(to bottom, #d8def8, #d8def8, #ffffff) !important; }
.bg-gradient-dark { background-image: linear-gradient(to bottom, #5c5c5c, #353535) !important; }

.unset-p { padding: unset !important; }
.unset-b { border: unset !important; }
.unset-m { margin: unset !important; }

.float-right { float: right; }
.float-left { float: left; }

.transition4 { transition: 0.4s; }
.transition5 { transition: 0.5s; }
.transition6 { transition: 0.6s; }

.poppins { font-family: 'Poppins', sans-serif !important; }
.unset-white-space { white-space: unset !important; }
.pre-wrap-white-space { white-space: pre-wrap; }/* check use for preWrap in non dialog comps */
.box-shadow { box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%) !important; }

.main {
  border-bottom: 2px solid var(--blue);
  min-height: 100%;
}

.view-container {
  flex: 1 !important;
  width: 85%;
  padding: 0;
}

::placeholder { 
  opacity: 0.8 !important; 
  color: #fff !important;
}

.container {
  margin: unset !important;
  padding: unset !important;
}

.row {
  margin: unset !important;
  padding: unset !important;
  display: flex !important;
}

.map-container {
  height: 78vh !important;
  width: 100% !important;
  border-radius: 4px !important;
}

/* check if can be moved to controls.css (maybe be better to create a prime-controls.css? */
.p-overlaypanel:before, .p-overlaypanel:after {
  display: none !important;
}
.p-inputtext { font-size: 14px !important; }
.p-calendar { width: 100% !important; }

.p-button {
  background: transparent !important;
  border: 1px solid !important;
  color: var(--green) !important;
}
.pi {
  font-size: 14px !important; 
  font-family: 'primeicons' !important;
}

.p-inputtext {
  background-color: transparent !important;
}

/*devexpress styles being overridden*/
.dx-dashboard-export { display: none !important; }/* hides dashboard export button - must be in this file */
.dx-checkbox-text { padding-left: 35px !important; }
.dx-designer .dx-designer-viewport .dx-button-has-icon .dx-button-content { padding: 0px !important; }
.dx-gantt-view > div { max-height: 85vh !important; }
.dx-treelist .dx-gantt-view {  max-height: 86vh !important; }
.dx-gantt { max-height: 90vh !important; }
.dx-gantt .dx-gantt-toolbar-wrapper { padding: unset !important; }

/* this style keeps the default font family for report and dashboard designer sql query designer */
.ace_line > * {
  font-family: monospace !important;
}


.change-scrollbar-color ::ng-deep ::-webkit-scrollbar-track {
  background: #fff !important;
  transition: 0.7s !important;
}

.change-scrollbar-color ::ng-deep ::-webkit-scrollbar {
  width: 5px !important;
  height: 8px !important;
}

.change-scrollbar-color ::ng-deep ::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 10px !important;
}

.change-scrollbar-color-darkMode ::ng-deep ::-webkit-scrollbar {
  width: 5px !important;
  height: 8px !important;
}

.change-scrollbar-color-darkMode ::ng-deep ::-webkit-scrollbar-thumb {
  background: #f1f1f1 !important;
  border-radius: 10px !important;
}

.change-scrollbar-color-darkMode ::ng-deep ::-webkit-scrollbar-track {
  background-color: var(--dark) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--orange) !important;
}