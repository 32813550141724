.p-overlaypanel {
  width: -webkit-fill-available !important;
  max-width: 40% !important; 
  background-color: transparent !important;  
  box-shadow: unset !important;
}

.p-component-overlay {
  backdrop-filter: blur(5px);
}

.p-dialog {
  max-height: 95% !important;
}

.p-dialog-header-icon {
  display: none !important;
}

.p-dialog-header {
  padding: 0px !important;
}

.p-dialog-content {
  padding: 0 !important;
  border-radius: 4px !important;
}

.dialog-title-container {
  top: 0px !important; 
  z-index: 5;
}

.dialog-title {
  padding: 0px 10px;
  height: 75px;
}

.dialog-title-icon {
  margin-right: 5px;
  padding: 8px 12px;  
}

.dialog-footer-container { 
  bottom: 0px !important; 
  z-index: 5;
}

.dialog-footer { 
  border-top: 1px solid #dedede;
}

.form-select {
  height: 35px;
  border-color: #dedede; 
}