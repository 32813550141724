.custom-loaderContainer {
  background: #0000007d !important;
  width: 100%;
  height: 100vh;
  z-index: 100000 !important;
  backdrop-filter: blur(5px);
}

.custom-loader {
  width: 100px;
  height: 48px;
  background: radial-gradient(circle closest-side, var(--blue) 90%, #0000) 0% 50%, radial-gradient(circle closest-side, var(--green) 90%, #0000) 50% 50%, radial-gradient(circle closest-side, var(--orange) 90%, #0000) 100% 50%;
  background-size: calc(100%/3) 24px;
  background-repeat: no-repeat;
  animation: d3 1s infinite linear;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%
  }

  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%
  }

  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%
  }

  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%
  }
}