.mat-tab-label-content {
  color: var(--black) !important;
  font-family: 'Poppins', sans-serif !important;
}

.mat-tab-group.mat-primary .mat-ink-bar {
  background: var(--orange) !important;
}

.mat-tab-label {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #ebebeb;
  margin-right: 5px;
  height: 40px !important;
  opacity: 1 !important;
}

.mat-tab-body.mat-tab-body-active {
  animation: fadeIn 0.5s !important;
}

.mat-mdc-tab-group {
  flex-direction: unset !important;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
  border-color: var(--green) !important;
}

.mat-mdc-tab.mdc-tab {  
  height: 40px !important;
  margin-right: 5px !important;
  border-radius: 4px 4px 0px 0px !important;
  width: 155px !important;
}

.mdc-tab {
  min-width: unset !important;
}

.mat-mdc-tab-header-pagination {
  display: none !important;
}