button,
input,
select,
th,
td,
p,
label {
  font-family: 'Poppins', sans-serif !important;
}

a {
  font-family: 'Poppins', sans-serif !important;
  text-decoration: none !important;
  transition: .1s !important;
}

a:hover { color: var(--orange) !important; }

select { 
  background-color: var(--dark-second) !important; 
  border-radius: 4px !important;
}

/* i { background: transparent !important; } */

.form-control {
  transition: unset !important;
  border-radius: 4px !important;
  box-shadow: unset !important;
  background-color: var(--dark-second) !important;
}

.btn {
  padding: 7px 10px !important;
  border-radius: 4px !important;
  box-shadow: unset !important;
  border: unset !important;
  transition: 0.4s !important;
}

.btn:hover {
  background: var(--dark-second) !important;
}

/* for report/dashboard viewer via direct url */
.btn-back {
  top: 14px; 
  left: 0px; 
  width: auto; 
  z-index: 5;
  background: var(--dark-second);
}